import { observer } from "mobx-react-lite";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { FC, FormEvent, useContext, useRef, useState } from "react";
import { Context } from "../..";

const UserSettings:FC = () => {
    const { store } = useContext(Context);
    const login = store.user.login;
    const [name, setName] = useState(store.user.name);
    const [password, setPassword] = useState('');

    const toast = useRef<any>();

    const ValidForm = async () => {
        let isValid = true;

        if (name.length < 3) { isValid = false; toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Имя не может быть короче 3х символов', life: 3000 }); }
        if (name.length > 11) { isValid = false; toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Имя не может быть длиннее 10 символов', life: 3000 }); }

        if (password.length > 0 && password.length < 6) { isValid = false; toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Пароль не может быть короче 6 символов', life: 3000 }); }
        if (password.length > 50) { isValid = false; toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Пароль не может быть длиннее 50 символов', life: 3000 }); }

        return isValid;
    }

    const submitSettingsForm = async (e:FormEvent) => {
        e.preventDefault();
        const isValid = await ValidForm();
        if (!isValid) return false;

        // Заблокировать поля ввода, затем выдать тост с ответом
        const result = await store.updateUserData(name, password)

        if (!result) {
            toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Произошла ошибка. Попробуйте позже', life: 3000 });
        } else {
            toast.current.show({ severity: 'success', summary: 'Успех!', detail: 'Ваши данные успешно обновлены!', life: 3000 });
            setPassword('');
        }
    }

    const removeSessions = async (e:any) => {
        e.preventDefault();

        const result = await store.removeUserSessions();

        if (!result) {
            toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Произошла ошибка. Попробуйте позже', life: 3000 });
        } else {
            console.log(result);
            toast.current.show({ severity: 'info', summary: 'Успех!', detail: 'Все сессии, кроме нынешней завершены', life: 3000 });
        }
    }
 
    return (
        <div className="grid">
            <Toast ref={toast} />

            <form className="col-12 md:col-6" method="post" onSubmit={(e) => submitSettingsForm(e)}>
                <div className="card p-fluid">
                    <h5>Изменить данные</h5>
                    <div className="field">
                        <label htmlFor="name">Логин</label>
                        <InputText disabled id="login" type="text" value={login} />
                    </div>
                    <div className="field">
                        <label htmlFor="name">Имя</label>
                        <InputText id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Пароль (* не заполняйте, чтобы оставить прежним.)</label>
                        <Password id="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <Button label="Сохранить"></Button>
                </div>
            </form>            
            <div className="col-12 md:col-6">

            </div>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    {/* <h5>Завершить</h5> */}
                    <Button label="Завершить все активные сессии" className="p-button-danger mr-2 mb-2" onClick={removeSessions}></Button>
                </div>
            </div>
        </div>

    )

}

export default observer(UserSettings);