import { observer } from "mobx-react-lite"
import { Button } from "primereact/button"
import { InputSwitch } from "primereact/inputswitch"
import { InputTextarea } from "primereact/inputtextarea"
import { Toast } from "primereact/toast"
import { ChangeEvent, FC, useRef, useState } from "react"
import messageStore, { UploadedFileItemInterface, buttonDataInterface } from "../store/messageStore"
import { FileUpload } from "primereact/fileupload"
import { InputText } from "primereact/inputtext"
import config from "../config"
import { AxiosResponse } from "axios"



const MessageSender:FC = () => {
    const [destinationData, setDestinationData] = useState('');
    const [message, setMessage] = useState('');
    const [toAll, setToAll] = useState(false);
    const [withPhoto, setWithPhoto] = useState(false);
    const [withButton, setWithButton] = useState(false);
    const [sendCsvFile, setSendCsvFile] = useState(false);
    const [withAttachment, setWithAttachment] = useState(false);
    const [buttonData, setButtonData] = useState<buttonDataInterface>({text: '', url: ''})
    const [imagesList, setImageList] = useState<UploadedFileItemInterface[]>([]);
    const [techName, setTechName] = useState('');
    const [csvFileInputValue, setCsvFileInputValue] = useState<any>();
    const [attachmentFile, setAttachmentFile] = useState<any>(null)
    const [withCatalogButton, setWithCatalogButton] = useState(false);

    const toast = useRef<any>();

    const showSuccess = (msg:any) => {
        toast?.current.show({ severity: 'success', summary: 'Успех!', detail: msg, life: 3000 });
    };

    const showToast = (severity: string, summary: string, msg:any, life: number) => {
        toast?.current.show({ severity, summary, detail: msg, life });
    };

    const sendMessage = () => {
        if (imagesList.length > 0) {}
        else if ( !(message.length > 5 && (toAll || destinationData.length > 8)) ) { return; }

        messageStore.sendMessage(toAll?'all':destinationData, {
            message: message,
            buttonData: withButton?buttonData:false,
            catalogButton: withCatalogButton?withCatalogButton:false,
            imagesData: withPhoto?imagesList:false,
            attachmentFile: attachmentFile?attachmentFile:false,
            techName,
        });

        setDestinationData('');
        setMessage('');
        setToAll(false);
        setWithButton(false);
        setWithCatalogButton(false);
        setButtonData({});
        setWithPhoto(false);
        setImageList([]);
        setTechName('');
        setCsvFileInputValue(null)
        setAttachmentFile(null)
        setWithAttachment(false)


        showSuccess('Сообщение успешно отправлено');
    }

    const imageUp = (i: number) => {
        if (i == 0) return;
        const _images = [...imagesList];
        const el = _images[i];
        _images.splice(i, 1);
        _images.splice(i-1, 0, el);
        setImageList(_images);
    }

    const imageDown = (i: number) => {
        if (i == imagesList.length-1) return;
        const _images = [...imagesList];
        const el = _images[i];
        _images.splice(i, 1);
        _images.splice(i+1, 0, el);
        setImageList(_images);
    }

    const imageRemove = (i: number) => {
        const _images = [...imagesList];
        _images[i].isRemoved = true;
        setImageList(_images);
    }

    const onImageUpload = (response: AxiosResponse, _imageList: UploadedFileItemInterface[]) => {
        const { data } = response;
        
        _imageList.map((imageData, j) => {
            const name = imageData.name;
            for (let i = 0; i < data.length; i++) {
                const uploadData = data[i];
                if (uploadData.name != name) continue;

                _imageList[j].url = `${config.API_URL}${uploadData.path}`
                _imageList[j].path = `${uploadData.path}`
                _imageList[j].isUploaded = true;
                break;
            }
        })

        setImageList(_imageList);
    }

    const uploadImages = (images: FileList | null) => {
        if (images == null) { return; }
        const _imageList = [...imagesList];
        // Если изображения > 2мб, то варн

        Array.from(images).map((image) => {
            if (image.size > 2000000) { 
                showToast('error', image.name, `Размер файла больше 2мб`, 5000)
                return;
            }

            _imageList.push({
                isRemoved: false,
                isUploaded: false,
                name: image.name,
                path: '',
                url: '',
            })
        })

        // setImageList(_imageList);

        messageStore.uploadImages(images).then((response) => {
            onImageUpload(response, _imageList);
        });
    }

    const FileUploaderItem = (props: {i: number, image: UploadedFileItemInterface}) => {
        return (
            <div key={props.i} className="fileUploader__listItem">
                <div className="fileUploader__buttons">
                    <button onClick={() => {imageUp(props.i)}}>▲</button>
                    <button onClick={() => {imageRemove(props.i)}}>✖</button>
                    <button onClick={() => {imageDown(props.i)}}>▼</button>
                </div>

                <div 
                    style={{backgroundImage: props.image.isUploaded?`url('${props.image.url}')`:''}}
                    className={`fileUploader__image ${!props.image.isUploaded?'isUploading':''}`} ></div>
                <span>{props.image.name}</span>
            </div>
        )
    }

    const FileUploader:FC = () => {
        return (
            <div className="fileUploader">
                <input 
                    type="file" 
                    className="fileUploader__input" 
                    accept="image/jpeg,image/jpg,image/png"
                    multiple
                    onChange={(e) => {
                        uploadImages(e.target.files)
                    }} />

                <div className="fileUploader__list">
                    {imagesList?.length>0 && imagesList.map((image, i ) => {
                        if (image.isRemoved) { return ''; }
                        return (
                            <FileUploaderItem key={i} i={i} image={image} />
                        )
                    })}
                </div>
            </div>
        )
    }

    const PhoneFileUploader:FC = () => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        // Читаем данные из csv
        const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files) {return;}

            try {
                const phoneFile = e.target.files[0]
                const phoneFileUrl = URL.createObjectURL(phoneFile)
                const phoneFileData = (await (await fetch(phoneFileUrl)).text()).split('\n')

                /*
                    1. Убираем из файла 8, +7, знаки препинания, спецсимволы
                    3. Под инпутом пишем количество загруженных номеров
                */

                phoneFileData.map((dataLine, i) => {
                    let dataitem = phoneFileData[i];
                    if (dataitem[0] == '+') { dataitem = dataitem.substring(1) } 
                    if (dataitem[0] == '7' || dataitem[0] == '8') { dataitem = dataitem.substring(1) } 

                    phoneFileData[i] = dataitem.replace(/[^\w\s]/gi, '');
                })

                if (phoneFileData.length == 0) { return; }

                setDestinationData(phoneFileData.join(','));


            } catch (e) {
                console.log(e);
            }
        }

        return (
            <div className="fileUploader">
                <input 
                    type="file" 
                    className="fileUploader__input" 
                    accept=".csv"
                    style={{marginBottom: '10px'}}
                    onChange={(e) => {
                        onFileSelect(e)
                        setCsvFileInputValue(e.target.files)
                    }} />
                    { csvFileInputValue && 
                        <div>{csvFileInputValue[0].name} </div> }
                    <br/>

            </div>
        )
    }

    const AttchmentSelector:FC = () => {
        return (
            <>
                <div className="field">
                    <label><b>Добавить вложение (png, gif, pdf, аудио)</b></label> <br />
                    <InputSwitch checked={withAttachment} onChange={(e) => {
                        setWithAttachment(e.value)
                        if (e.value) setWithPhoto(false)
                        if (!e.value) setAttachmentFile(null)
                    }} />
                </div>

                {withAttachment && attachmentFile == null &&
                    <>
                        <input 
                        type="file" 
                        className="fileUploader__input" 
                        // accept=""
                        // value={attachmentFile}
                        onChange={(e) => {
                            console.log(e.target.files?e.target.files[0]:false);
                            setAttachmentFile(e.target.files?e.target.files[0]:null);
                        }} /><br /><br />
                    </>
                    }

                {withAttachment && attachmentFile != null && 
                    <div>
                        <button onClick={() => setAttachmentFile(null)}>✖</button> &nbsp;
                        Выбран файл: {attachmentFile?attachmentFile.name:false} <br />
                        <br /><br />
                    </div>
                }
            
            </>
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h3>Рассылка сообщений</h3>
                    <h6>
                        <div>&lt;b&gt;Жирно&lt;/b&gt;</div>
                        <div>&lt;i&gt;Курсив&lt;/i&gt;</div>
                        <div>&lt;u&gt;Подчёркивание&lt;/u&gt;</div>
                        <div>&lt;s&gt;Зачёркнуто&lt;/s&gt;</div>
                    </h6>
                    <div>
                        <h5>Сообщение</h5>
                        <h6>Для отправки сообщения с указанием имени пользователя добавьте тег: "[username]"</h6>
                        <InputTextarea 
                            placeholder="Текст.." autoResize rows={5} className="customfieds__textarea" value={message} onChange={(e) => {setMessage(e.target.value)}} />
                    </div><br />

                    <div className="field">
                        <label><b>Рассылка с кнопкой</b></label> <br />
                        <InputSwitch checked={withButton} onChange={(e) => {
                            setWithButton(e.value)
                            if (e.value) {
                                setWithPhoto(false);
                            }
                        }} />
                    </div>

                    {withButton && 
                        <>
                        <label><b>Текст</b></label> <br />
                        <InputText value={buttonData?.text} onChange={(e) => {
                            const _temp = {...buttonData};
                            _temp.text = e.target.value;
                            setButtonData(_temp);
                        }} /><br /><br />

                        <label><b>Ссылка (Только HTTPS!)</b></label> <br />
                        <InputText value={buttonData?.url} onChange={(e) => {
                            const _temp = {...buttonData};
                            _temp.url = e.target.value;
                            setButtonData(_temp);
                        }} /><br /><br />
                        </>
                    }

                    <div className="field">
                        <label><b>Рассылка с кнопкой "В каталог"</b></label> <br />
                        <InputSwitch checked={withCatalogButton} onChange={(e) => {
                            setWithCatalogButton(e.value)
                            if (e.value) {
                                setWithButton(false);
                            }
                        }} />
                    </div>

                    <div className="field">
                        <label><b>Рассылка с изображениями</b></label> <br />
                        <InputSwitch checked={withPhoto} onChange={(e) => {
                            setWithPhoto(e.value)
                            if (e.value) {
                                setWithAttachment(false);
                                setAttachmentFile(null)
                                setWithButton(false);
                            }
                        }} />
                    </div>

                    {withPhoto && 
                        <FileUploader />
                    }

                    <AttchmentSelector />

                    <div className="field">
                        <label><b>Рассылка для всех пользователей</b></label> <br />
                        <InputSwitch checked={toAll} onChange={(e) => setToAll(e.value)} />
                    </div>

                    {toAll === false && 
                        <div className="field">
                            <label><b>Загрузить получателей файлом (Формат csv. каждый external_id, либо номер с новой строки)</b></label> <br />
                            <InputSwitch checked={sendCsvFile} onChange={(e) => {
                                setSendCsvFile(e.value)
                                setDestinationData('');
                                }} />
                        </div>
                    }

                    {toAll === false && sendCsvFile === true && 
                        <PhoneFileUploader />
                    }

                    {toAll === false &&
                        <div>
                            <h5>External_id, или номера телефонов (Без +7 или 7), через запятую</h5>
                            <h6>Если пользователь с такими данными будет найдён, то он получит сообщение</h6>
                            <InputTextarea 
                                placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={destinationData} onChange={(e) => {setDestinationData(e.target.value)}} />
                            <br /><br />
                        </div>
                    }

                    <div>
                        <label><b>Техническое наименование (для статистики)</b></label> <br />
                        <InputText value={techName} onChange={(e) => { setTechName(e.target.value); }} /><br /><br />
                    </div>


                    <br />
                    <Button label="Отправить" className="p-button-success mr-2 mb-2" onClick={sendMessage} />
                    {/* <Button label="Тест" className="p-button mr-2 mb-2" onClick={test} /> */}
                </div>
            </div>

            <Toast ref={toast} />
        </div>
    )
}

export default observer(MessageSender)