import UserService from "../services/UserService";

export default class UsersStore {
    data = [];

    setData(data:any) {
        this.data = data;
    }
    
    constructor () {
    
    }

    async loadUsers() {
        try {
            const responce = await UserService.getUserList();
            return responce.data
        } catch (e:any) {
            return e?.response.data.message
        }
    }

    async createUser(name:any, login:any, password:any, repeat_password:any, accessType: any, roles: any) {
        try {
            const response = await UserService.createUser(name, login, password, repeat_password, accessType, roles);
            return response.data;
        } catch (e:any) {
            return e?.response.data.message
        }
    }

    async getUserData(id: any) {
        try {
            const response = await UserService.getUser(id);
            return response.data;
        } catch (e:any) {
            return e?.response.data.message
        } 
    }

    async editUser(id:any, name:any, login:any, password:any, repeat_password:any, accessType: any, roles: any) {
        try {
        const response = await UserService.editUser(id, name, login, password, repeat_password, accessType, roles);
        return response.data;
        } catch (e:any) {
        return e?.response.data.message
        } 
    }

    async removeUser(id:any) {
        try {
            const response = await UserService.removeUser(id);
            return response.data;
        } catch (e:any) {
            return e?.response.data.message
        } 
    }
}

//  new UsersStore();