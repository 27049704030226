import { observer } from "mobx-react-lite";
import { FC } from "react";
import { NavLink } from "react-router-dom";

interface PaginatorProps {
    curPage: string,
    lastPage: any,
    url: string,
}

const Paginator:FC<PaginatorProps> = (props) => {

    const curPage = parseInt(props.curPage);
    const lastPage = parseInt(props.lastPage)

    const Pages = () => {
        const curPageNum = curPage + 1;

        const PageItems:any[] = [];

        if (lastPage <= 5) {
            for (let i = 1; i <= lastPage+2; i++) {
                PageItems.push(i);
            }
        } else if (curPage < 3 && lastPage > 5) {
            for (let i = 1; i <= 5; i++) {
                PageItems.push(i);
            }
        } else if (curPage > 2 && curPage < lastPage ) {
            for (let i = curPage-2; i <= curPage+2; i++) {
                PageItems.push(i);
            }
        } else if (curPage > 2 && curPage == lastPage ) {
            for (let i = curPage-3; i <= lastPage+1; i++) {
                PageItems.push(i);
            }
        } else {
            for (let i = 1; i <= lastPage+1; i++) {
                PageItems.push(i);
            }
        }

        return (
            <span className="p-paginator-pages">
                {PageItems.map((item) => 
                    <NavLink to={`${props.url}/${item-1}`} key={item}>
                        <button type="button" className={`p-paginator-page p-paginator-element p-link ${item==curPageNum?'p-paginator-page-start p-highlight':''}`}>{item}<span className="p-ink"></span></button>
                    </NavLink>
                )}
            </span>
        )
    }

    return (
        <div className="p-datatable p-datatable-gridlines">
            <div className="p-paginator p-component p-paginator-bottom">
                <NavLink to={`${props.url}`}>
                    <button type="button" className="p-paginator-first p-paginator-element p-link">
                        <span className="p-paginator-icon pi pi-angle-double-left"></span>
                        <span className="p-ink"></span>
                    </button> 
                </NavLink>
                <NavLink to={`${props.url}/${parseInt(props.curPage)-1}`} className={props.curPage}>
                    <button type="button" className="p-paginator-prev p-paginator-element p-link">
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span className="p-ink"></span>
                    </button>
                </NavLink>

                <Pages />
                
                <NavLink to={`${props.url}/${parseInt(props.curPage)+1}`}>
                <button type="button" className="p-paginator-next p-paginator-element p-link">
                    <span className="p-paginator-icon pi pi-angle-right"></span><span className="p-ink"></span>
                </button>
                </NavLink>

                <NavLink to={`${props.url}/${props.lastPage}`}>
                    <button type="button" className="p-paginator-last p-paginator-element p-link" >
                        <span className="p-paginator-icon pi pi-angle-double-right"></span>
                        <span className="p-ink"></span>
                    </button>
                </NavLink>
            </div>	
        </div>
    )
};

export default observer(Paginator);