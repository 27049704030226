import { Axios, AxiosResponse } from "axios";
import $api from "../http";
import { AuthResponce } from "../models/responce/AuthResponce";

export default class AuthService {
    static async login(login: string, password: string): Promise<AxiosResponse<AuthResponce>> {
        return $api.post('/admin/login', {login, password});
    }

    static async logout(): Promise<void> {
        return $api.post('/admin/logout');
    }
}


