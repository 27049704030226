import $api from "../http";

export default class CustomFieldsService {
    static async saveData(data: any, attachments: any[]) {
        const fd = new FormData();
        fd.append("data", JSON.stringify(data));
        fd.append("firstMsgAttachment", attachments[0]);

        return $api.post('/admin/fields/save', fd);
    }

    static async getData() {
        return $api.get('/admin/fields');
    }
}