import $api from "../http";
import { messageDataInterface } from "../store/messageStore";

export default class BotMsgService {
    static async sendMessage(data:string, messageData: messageDataInterface) {
        if (messageData.attachmentFile) {
            const messageFormData = new FormData();
            messageFormData.append("data", data)
            messageFormData.append("messageData", JSON.stringify({
                message: messageData.message,
                buttonData: messageData.buttonData,
                catalogButton: messageData.catalogButton,
                imagesData: messageData.imagesData,
                techName: messageData.techName,
            }))
            if (typeof messageData.attachmentFile != 'boolean') {
                messageFormData.append("attachment", messageData.attachmentFile);
            }

            return $api.post('/admin/sendmessage', messageFormData);
        }

        return $api.post('/admin/sendmessage', {data, messageData});
    }

    static async uploadImages(files: FileList) {
        // const res = 
        const data = new FormData() ;
        Array.from(files).map((file) => {
            data.append('files', file);
        })

        return $api.post("/admin/sendmessage/photos", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}