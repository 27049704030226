import MedService from "../services/MedService";

export interface MedListInterface {
    id: string,
    external_id: string,
    name: string,
    city: string,
    speciality: string,
    employee_number: string,
    phone: string,
    tg_ticket: string,
}


export default class medStore {
    async uploadMeds(file:any) {
        try {
            const res = await MedService.UploadMeds(file);
            return res;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }

    async getMedList(page: any) {
        try {
            const res = await MedService.getMedList(page);
            return res.data;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }
}