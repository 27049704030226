import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import PostStore, { Category } from "../../store/postStore";
import { Context } from "../..";
import PostEdit from "./PostEdit";
import config from "../../config";

interface BreadcrumbItem {
    to: number;
    label: string;
}

const PostCategoriesList:FC = () => {
    const { store } = useContext(Context);
    const postStore = new PostStore();
    const [ category, setCategory ] = useState<Category[]>();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [deleteLabel, setDeleteLabel] = useState('');
    const [idToDelete, setIdToDelete] = useState<any>(-1);
    const [idToEdit, setIdToEdit] = useState<any>(-1);

    const [editDisplay, setEditDisplay] = useState(false);
    const [createDisplay, setCreateDisplay] = useState(false);

    const removeDialogFooter = (
        <>
            <Button type="button" label="Нет" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" autoFocus />
            <Button type="button" label="Да" icon="pi pi-check" onClick={() => removeItem(idToDelete)} className="p-button-text" />
        </>
    );

    const getCategory = async (id:number) => {
        const category = await postStore.getCategories(id);
        return category;
    }

    const showRemoveDialog = (label:string, id:any) => {
        setDisplayConfirmation(true);
        setDeleteLabel(label);
        setIdToDelete(id);
    }

    const loadCategory = async (parentID:number, parent:any = undefined) => {
        const result = await getCategory(parentID)
        setCategory(result);

        if (parent != undefined) {
            breadcrumbs.push({to: parent.id, label: parent.title})
        }
    }

    const empty = () => {
        setCategory([]);
    }
    
    const reloadCategory = () => {
        loadCategory(breadcrumbs.length>0?breadcrumbs[breadcrumbs.length-1].to:0);

        setEditDisplay(false);
        setCreateDisplay(false);
    }

    const removeItem = async (id: string) => {
        await postStore.removeItem(id);
        reloadCategory();
        setDisplayConfirmation(false);
    }

    useEffect(() => {
        loadCategory(0);
        setBreadcrumbs([]);
    }, [])
    

    const CategoriesList = () => {
        
        return (
            <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                <table className="p-datatable-table" role="table">
                    <thead className="p-datatable-thead">
                        <tr role="row">
                            <th className="" role="columnheader"></th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Название</span>
                                </div>
                            </th>

                            <th className="" role="columnheader"></th>
                        </tr>
                    </thead>
                    <tbody className="p-datatable-tbody">
                        {(!category || category.length == 0)?false:category.map((item:Category) => {
                            return (
                                <tr key={item.id} role="row" className="">
                                    <td role="cell" className="text-center cell-button">
                                        { (store.user.role == 'editor' && item.parent == 0) ||
                                        <Button icon="pi pi-pencil" className="p-button-blank" onClick={() => {
                                            setIdToEdit(item.id);
                                            setEditDisplay(true)
                                        }}/> }
                                    </td>
                                    <td role="cell" className="td-click td-flex" onClick={() => (item.isPost==false?loadCategory(item.id, item):false)}>
                                        {item.isVisible == false && <span className="p-button-icon p-c pi pi-eye-slash">&nbsp;</span>}
                                        {item.image && <img src={`${config.API_URL}${item.image}`} className="td-image" /> }
                                        {item.isPost == true && <span className="p-button-icon p-c pi pi-link">&nbsp;</span>}
                                        {item.title}
                                    </td>
                                    <td role="cell" className="text-center cell-button"><Button icon="pi pi-trash" className="p-button-blank" onClick={() => showRemoveDialog(item.title, item.id)} /></td>
                                </tr>                        
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    const BreadCrumbs = () => {
        return (
            <nav className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
                <li className="p-breadcrumb-home">
                    <a href="#" className="p-menuitem-link" onClick={() => {
                        loadCategory(0); breadcrumbs.length = 0;
                    }}>
                        <span className="p-menuitem-icon pi pi-home"></span>
                    </a>
                </li>
                {breadcrumbs.map((item) => {
                    const index = breadcrumbs.indexOf(item);
                    if (item.to == undefined) { return false }
                    return (
                        <li key={index}><a href="#" className="p-menuitem-link" onClick={() => {
                            loadCategory(item.to, item);
                            setBreadcrumbs(breadcrumbs.slice(0, index+1));
                        }}>
                            <span className="p-breadcrumb-chevron pi pi-chevron-right breadcrumb-chevron"></span>
                            <span className="p-menuitem-text">{item.label}</span></a>
                        </li>
                    )
                })}
            </ul>
        </nav>
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="">
                        <div className="card__header">
                            <h5>Контент</h5>
                            { (store.user.role == 'editor' && breadcrumbs.length == 0) ||
                            <Button label="Добавить" className="p-button p-component p-button-outlined mr-2 mb-2" onClick={ () => {
                                setCreateDisplay(true);
                            }} /> }
                        </div>
                        
                        <BreadCrumbs />
                        <br />
                        <CategoriesList />
                    </div>
                    
                </div>
            </div>

            <Dialog header="Создать" visible={createDisplay} style={{ width: '50vw' }} modal onHide={() => reloadCategory()}>
                <PostEdit parent={breadcrumbs.length>0?breadcrumbs[breadcrumbs.length-1].to:0} onCreate={() => {
                    reloadCategory()
                    setCreateDisplay(false);
                }} />
            </Dialog>

            <Dialog header="Редактировать" visible={editDisplay} style={{ width: '50vw' }} modal onHide={() => reloadCategory()}>
                <PostEdit type="edit" postId={idToEdit} />
            </Dialog>

            <Dialog header="Подтверждение" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '450px' }} modal footer={removeDialogFooter}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        Вы действительно хотите удалить элемент {deleteLabel}?
                        <br /><br />
                        Все вложеные данные будут потеряны
                     </span>
                </div>
            </Dialog>
        </div>
    )
}

export default observer(PostCategoriesList);