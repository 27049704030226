import StatService from "../services/StatService"

export default class statStore {
    async getBotMessageLog(page: any) {
        try {
            const res = await StatService.getBotMessages(page);
            return res.data;
        } catch (e:any) {
            return e?.response.data.message
        }
    }

    async getUserData(page: any) {
        try {
            const res = await StatService.getUserData(page)
            return res.data;
        } catch (e: any) {
            return e?.response.data.message
        }
    }

    async getFullUserData(page) {
        try {
            const res = await StatService.getFullUserData(page)
            return res.data;
        } catch (e: any) {
            return e?.response.data.message
        }
    }

    async getUserActions(tg_id: number) {
        try {
            const res = await StatService.getUserActions(tg_id)
            return res.data;
        } catch (e: any) {
            return e?.response.data.message
        }
    }

    async getCSV(type: string) {
        try {
            const res = await StatService.getCSV(type)
            // return res.data;
        } catch (e: any) {
            return e?.response.data.message
        }
    } 
}