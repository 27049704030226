import { observer } from "mobx-react-lite";
import { FC, FormEvent, useContext, useRef, useState } from "react";
import { Context } from "..";

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';


const LoginForm: FC = () => {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { store } = useContext(Context);
    const toast = useRef<any>();

    const showWarn = (msg:any) => {
        toast?.current.show({ severity: 'error', summary: 'Ошибка', detail: msg, life: 3000 });
    };

    const submitLoginForm = async (e:FormEvent) => {
        e.preventDefault();
        const result = await store.login(login, password)

        if (result) {
            console.log(result)
            showWarn(result)
        }
    }


    return (

        <div className="grid grid-fullpage">
            <Toast ref={toast} />
            <div className="col-5 col-sm-12">
                <div className="card">
                    <h5>Авторизация</h5>
                    <br />
                    <div className="grid grid-block">
                         <form className="p-fluid" method="POST" onSubmit={(e) => submitLoginForm(e)}>
                            <div className="field">
                                <span className="p-float-label">
                                    <InputText id="username" type="text" value={login} onChange={e => setLogin(e.target.value)}  />
                                    <label htmlFor="username">Логин</label>
                                </span>
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <InputText id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                                    <label htmlFor="password">Пароль</label>
                                </span>
                            </div>
                            <Button label="Вход" onClick={() => store.login(login, password)}></Button>
                        </form>
                    </div>
                </div>
                
                {/* <div>
                    <input 
                        onChange={e => setLogin(e.target.value)}
                        value={login}
                        type="text"
                        placeholder="Логин"
                    />

                    <input 
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        placeholder="Пароль"
                    />

                    <input type="submit" value="Логин"  onClick={() => store.login(login, password)}/>
                </div> */}
            </div>
        </div>
    )
}

export default observer(LoginForm);