import { observer } from "mobx-react-lite";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Checkbox } from 'primereact/checkbox';
import { FC, FormEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IUser } from "../../models/IUser";
import UsersStore from "../../store/usersStore";
import PostStore, { Category } from "../../store/postStore";

const UserCreate:FC = () => {
    const { id } = useParams();
    const usersStore = new UsersStore();
    const postStore = new PostStore();

    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [repeat_password, setRepeatPassword] = useState('');
    const [isUserNotAdmin, setIsUserNotAdmin] = useState(false);
    const [rolesValue, setRolesValue] = useState<any[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    const toast = useRef<any>();

    const showWarn = (msg:any, severity:any = 'error', summary:any = 'Ошибка') => {
        toast.current.show({ severity: severity, summary: summary, detail: msg, life: 3000 });
    };

    const showMsg = (text: any) => {
        showWarn(text, 'success', 'Успешно')
    }

    const showErrorMsg = (result:any) => {
        showWarn(result, 'error')
    }

    const submitUserForm = (e:FormEvent) => {
        e.preventDefault();

        if (id) {
            // Изменение пользователя
            const submit = async () => {
                const result = await usersStore.editUser(id, name, login, password, repeat_password, isUserNotAdmin, rolesValue)
                if (result) {
                    showErrorMsg(result);
                } else {
                    showMsg('Пользователь обновлён!');
                }
            }

            submit();

        } else {
            // Добавление пользователя
            const res = async () => {
                const result = await usersStore.createUser(name, login, password, repeat_password, isUserNotAdmin, rolesValue);
                // console.log(result)
                if (!result.result) {
                    showErrorMsg(result);
                } else {
                    showMsg('Пользователь создан!');
                }
            }

            res();
        }
    }

    const onCheckboxChange = (e:any) => {
        let selectedValue = [...rolesValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        console.log(rolesValue);

        setRolesValue(selectedValue);
    };

    const getCategories = async () => {
        if (isUserNotAdmin && categories.length == 0) {
            const categories = await postStore.getCategories(0);
            setCategories(categories);
        }
    }

    const userData = async () => { 
        const result = await usersStore.getUserData(id)

        setLogin(result.result.login);
        setName(result.result.name);

        setIsUserNotAdmin(result.result.role == 'admin'?false:true);
        let access = result.result.access.split(',');
        for (let item in access) {
            access[item] = parseInt(access[item]);
        }

        setRolesValue(access);

        console.log(result.result.access.split(','));
    };

    useEffect(() => {
        getCategories();
    }, [isUserNotAdmin])

    useEffect(() => {
        // Если указан пользователь, то загружаем его данные
        if (id) {
            userData();
        }

    }, [])

    let text:any = [];
    if (id) {
        text['header'] = 'Изменить пользователя'
        text['password'] = '* не заполняйте, чтобы оставить прежним.'

        
    } else {
        text['header'] = 'Добавить пользователя'
        text['password'] = 'не менее 6 символов'
    }    

    return (
        <div className="grid">
            <Toast ref={toast} />

            <form className="col-12 md:col-6" method="post" onSubmit={(e) => submitUserForm(e)}>
                <div className="card p-fluid">
                    <h5>{text.header}</h5>
                    <div className="field">
                        <label htmlFor="name">Логин</label>
                        <InputText id="login" type="text" value={login} onChange={(e) => setLogin(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="name">Имя</label>
                        <InputText id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Пароль ({text.password})</label>
                        <InputText id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        {/* <Password id="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} /> */}
                    </div>
                    <div className="field">
                        <label htmlFor="repeat_password">Повторите пароль</label>
                        <InputText id="repeat_password" type="password" value={repeat_password} onChange={(e) => setRepeatPassword(e.target.value)} />
                        {/* <Password id="repeat_password" type="text" value={repeat_password} onChange={(e) => setRepeatPassword(e.target.value)} /> */}
                    </div>

                    <div className="field">
                        <label htmlFor="repeat_password">Ограниченные права</label> <br />
                        <InputSwitch checked={isUserNotAdmin} onChange={(e) => setIsUserNotAdmin(e.value)} />
                        <br /><br />

                        {isUserNotAdmin && 
                            <div className="">
                                {categories.length>0?categories.map((item) => {
                                    return (
                                        <div className="field-checkbox" key={item.id}>
                                            <Checkbox inputId={`checkOption${item.id}`} name="option" value={item.id} checked={rolesValue.indexOf(item.id) !== -1} onChange={onCheckboxChange} />
                                            <label htmlFor={`checkOption${item.id}`}>{item.title}</label>
                                        </div>
                                    )
                                }):<></>}
                            </div>
                        }
                    </div>

                    <Button label="Сохранить"></Button>
                </div>
            </form>            
            <div className="col-12 md:col-6">

            </div>
        </div>
    )
}

export default observer(UserCreate)