import { observer } from "mobx-react-lite";
import { FC } from "react";
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";
import StatType1 from "./statType1";
import StatType2 from "./statType2";

const statList = (props: any) => {
    const { type } = props;

    

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    { type === "1" && 
                        <StatType1 />}
                    { type === "2" && 
                        <StatType2 />}
                </div>
            </div>
        </div>
    )
}

export default observer(statList);