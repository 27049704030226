import { observer } from "mobx-react-lite";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StatStore from "../../store/statStore";
import Paginator from "../Paginator";

interface userdata {
    id: number,
    tg_id: number,
    city: string,
    name: string,
    speciality: string,
    employee_number: string,
    external_id: string,
    webapp_open: string,
}

interface userDialog {
    id: number,
    tg_id: number,
    name: string,
}

interface userDialogData {
    id: number,
    tg_user_id: number,
    action: string,
    timestamp: string,
}

const StatType1 = () => {
    const { page } = useParams();

    const statStore = new StatStore();
    const [data, setData] = useState<userdata[]>([]);
    const [dialogDataLoad, setDialogDataLoad] = useState<boolean>(true);
    const [dialogData, setDialogData] = useState<userDialog>({ id: -1, tg_id: 0, name: '' });
    const [displayDialog, setDisplayDialog] = useState<boolean>(false);
    const [dialogActionsData, setDialogActionsData] = useState<userDialogData[]>([]);
    const [lastPage, setLastPage] = useState('');


    const timestampToDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        let hours = date.getHours() < 10?"0"+date.getHours():date.getHours();
        let minutes = date.getMinutes() < 10?"0"+date.getMinutes():date.getMinutes();

        return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()} ${hours}:${minutes}`
    }

    const getData = async () => {
        const res = await statStore.getFullUserData(page?page:'0');
        setData(res.data);
        setLastPage(res.pageCount);
    }

    const getUserHistory = async (tg_id: number) => {
        setDisplayDialog(true);

        statStore.getUserActions(tg_id).then((data) => {
            setDialogActionsData(data);
            setDialogDataLoad(false);

        });
    }

    useEffect(() => {
        getData();
    }, [page])

    return (
        <>
            <div className="card__header card__header--wbuttons">
                <h5>Статистика действий пользователей</h5>
                <button className="header__csv-btn" onClick={() => {statStore.getCSV('type1')}}>Выгрузить в csv</button>
            </div>
            <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                <table className="p-datatable-table" role="table">
                    <thead className="p-datatable-thead">
                        <tr role="row">
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Tg id</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">External id</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Name</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">City</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Speciality</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">EmployeeNumber</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Дата входа в WebApp</span>
                                </div>
                            </th>
                            <th className="" role="columnheader"></th>
                        </tr>
                    </thead>
                    <tbody className="p-datatable-tbody">
                        {data?data.map((item, i) => {
                            return (
                                <tr role="row" key={i}>
                                    <td role="cell">{item.tg_id}</td>
                                    <td role="cell">{item.external_id?item.external_id:'-'}</td>
                                    <td role="cell">{item.name?item.name:'-'}</td>
                                    <td role="cell">{item.city?item.city:'-'}</td>
                                    <td role="cell">{item.speciality?item.speciality:'-'}</td>
                                    <td role="cell">{item.employee_number?item.employee_number:'-'}</td>
                                    <td role="cell">{timestampToDate(item.webapp_open)}</td>
                                    <td role="cell"><Button icon="pi pi-arrow-down" className="p-button-blank" onClick={() => getUserHistory(item.tg_id)} /></td>
                                </tr>
                            )
                        }):<tr></tr>}
                    </tbody>
                </table>
                { lastPage != '0' && 
                    <Paginator curPage={page?page:'0'} lastPage={parseInt(lastPage)-1} url="/stats" />}
            </div>

            <Dialog header={dialogData.name} visible={displayDialog} onHide={() => {
                setDisplayDialog(false);
                setDialogDataLoad(true);
                
                }} style={{ width: '70vw' }} modal>
                {dialogDataLoad && 
                    <span>
                        Загрузка..
                    </span> 
                }

                {!dialogDataLoad && dialogActionsData.length > 0 &&
                    <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                        <table className="p-datatable-table" role="table">
                            <tbody className="p-datatable-tbody">
                                {dialogActionsData.map((item) => {
                                    return (
                                        <tr role="row" key={item.id}>
                                            <td role="cell">{item.action}</td>
                                            <td role="cell">{timestampToDate(item.timestamp)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                
            </Dialog>
        </>
    )
}

export default observer(StatType1);