import axios, { AxiosError } from "axios";
import { makeAutoObservable, observable } from "mobx";
import { useContext } from "react";
import { Context } from "..";
import { API_URL } from "../http";
import { IUser } from "../models/IUser";
import { AuthResponce } from "../models/responce/AuthResponce";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";

interface BreadcrumbItem {
    to: number;
    label: string;
}

export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = true;

    postBreadcrumbs = [];

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setUsername(name: string) {
        this.user.name = name;
    }

    setPostBreadcrumbs (item: any) {
        this.postBreadcrumbs = item;
    }

    getPostBreadcrumbs () {
        return this.postBreadcrumbs;
    }

    async login(login: string, password: string) {
        try {
            const responce = await AuthService.login(login, password);
            // console.log(responce.data.accessToken);

            localStorage.setItem('token', responce.data.accessToken);
            this.setAuth(true);
            this.setUser(responce.data.user);

            return true;

        } catch (e:any) {
            // console.log(e.response.data.message?e.response.data.message:e);
            return e?.response.data.message
        } finally {
            this.setLoading(false);
        }
    }

    async logout() {
        try {
            const responce = await AuthService.logout();
            localStorage.removeItem('token');

            this.setAuth(false);
            this.setUser({} as IUser);
        } catch (e:any) {
            console.log(e.response.data.message?e.response.data.message:e);
            return { message: e?.response.data.message }
        } finally {
            this.setLoading(false);
        }
    }

    async checkAuth() {
        this.setLoading(true);

        try {
            const responce = await axios.get<AuthResponce>(`${API_URL}/admin/refresh`, {withCredentials: true})

            localStorage.setItem('token', responce.data.accessToken);
            this.setAuth(true);
            this.setUser(responce.data.user);
        } catch (e:any) {
            console.log(e.response.data.message?e.response.data.message:e);
            return e?.response.data.message
            
        } finally {
            this.setLoading(false);
        }
    }

    async updateUserData(name: string, password: string = '') {
        // this.setLoading(t)

        try {
            const response = await UserService.updateCurrentUser(name, password);
            const { username } = response.data;
            if (username == null) {
                return false;
            }
            return username;
            // console.log(username);
            // this.setUsername('123')

        } catch (e:any) {
            console.log(e)
        }
    }

    async removeUserSessions() {
        try {
            const responce = await UserService.removeCurrentUserSessions();
            const { id } = responce.data;
            if (id == null) {
                return false;
            } 
            return id;

        } catch (e:any) {
            console.log(e);
        }
    }

}