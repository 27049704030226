import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StatStore from "../../store/statStore";
import Paginator from "../Paginator";

interface userData {
    id: string,
    tg_id: string,
    created: string,
    phone: string,
    webapp: string,
    external_id: string,
}

const StatType2 = () => {
    const { page } = useParams();
    const statStore = new StatStore();
    const [data, setData] = useState<userData[]>([]);

    const [startCounter, setStartCounter] = useState<number>(0)
    const [phoneNoVerifyCounter, setPhoneNoVerifyCounter] = useState<number>(0);
    const [phoneAcceptCounter, setPhoneAcceptCounter] = useState<number>(0);
    const [phoneMissVerifyCounter, setPhoneMissCounter] = useState<number>(0);
    const [webappStartCounter, setWebappStartCounter] = useState<number>(0);

    const [lastPage, setLastPage] = useState('');

    const getUserStat = async () => {
        const userdata = await statStore.getUserData(page?page:'0');
        setData(userdata.data);
        setLastPage(userdata.pageCount);

        setStartCounter(userdata.stat.count);

        // let phoneNoVerifyCounter = 0,
        //     phoneAcceptCounter = 0, 
        //     phoneMissCounter = 0, 
        //     webappCounter = 0;

        // userdata.data.map((item) => {
        //     if (!item.phone || item.phone.toString().length < 8) {
        //         phoneNoVerifyCounter++;
        //     } else if (item.external_id == -1) {
        //         phoneMissCounter++;
        //     } else {
        //         phoneAcceptCounter++;
        //     }

        //     if (!item.webapp || item.webapp.toString().length < 3) {
        //         webappCounter++;
        //     }
        // })

        setPhoneNoVerifyCounter(userdata.stat.noAuth);
        setPhoneAcceptCounter(userdata.stat.auth);
        setPhoneMissCounter(userdata.stat.noExtId);
        setWebappStartCounter(userdata.stat.noWebApp);
    }

    const timestampToDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        let hours = date.getHours() < 10?"0"+date.getHours():date.getHours();
        let minutes = date.getMinutes() < 10?"0"+date.getMinutes():date.getMinutes();

        return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()} ${hours}:${minutes}`
    }

    useEffect(() => {
        getUserStat();
    }, [page])
    

    return (
        <>
            <div className="card__header card__header--wbuttons">
                <h5>Статистика пользователей</h5>
                <button className="header__csv-btn" onClick={() => {statStore.getCSV('type2')}}>Выгрузить в csv</button>
            </div>
            <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                <table className="p-datatable-table" role="table">
                    <thead className="p-datatable-thead">
                        <tr role="row">
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Запуск бота</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Не пройдена проверка номера телефона</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Пройдена проверка номера телефона</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Номер телефона отсутствует в базе</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Не запускали WebApp</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="p-datatable-tbody">
                        <tr role="row">
                            <td role="cell">{startCounter}</td>
                            <td role="cell">{phoneNoVerifyCounter}</td>
                            <td role="cell">{phoneAcceptCounter}</td>
                            <td role="cell">{phoneMissVerifyCounter}</td>
                            <td role="cell">{webappStartCounter}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />

            <div className="card__header">
                <h5>Статистика 1</h5>
            </div>
            <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                <table className="p-datatable-table" role="table">
                    <thead className="p-datatable-thead">
                        <tr role="row">
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Tg id</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Запуск бота</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Проверка номера телефона</span>
                                </div>
                            </th>
                            <th className="" role="columnheader">
                                <div className="p-column-header-content">
                                    <span className="p-column-title">Первый запуск WebApp</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="p-datatable-tbody">
                        { data?.length>0?data.map((item) => {
                            let phoneState = '';
                            if (item.external_id == '-1') {
                                phoneState = 'Телефон отсутствует в базе';
                            } else if(!item.phone || item.phone.length < 8) {
                                phoneState = '-';
                            } else {
                                phoneState = 'Телефон подтвеждён';
                            }
                            const created = timestampToDate(item.created);
                            const webapp = item.webapp?timestampToDate(item.webapp):'-';
                            return (
                                <tr role="row" key={item.id}>
                                    <td role="cell">{item.tg_id}</td>
                                    <td role="cell">{created}</td>
                                    <td role="cell">{phoneState}</td>
                                    <td role="cell">{webapp}</td>
                                </tr>
                            )
                        }):<tr role="row"></tr> }
                    </tbody>
                </table>

                { lastPage != '0' && 
                    <Paginator curPage={page?page:'0'} lastPage={parseInt(lastPage)-1} url="/stats2" />}
            </div>
        </>
    )
}

export default observer(StatType2);