import { observer } from "mobx-react-lite"
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea"
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react"
import customFieldsStore from "../store/customFieldsStore";
import { InputSwitch } from "primereact/inputswitch";

type attachmentFileData = {
    enabled: boolean,
    fileName: String,
    fieldName: String,
    tgId: String,
    mimetype: String,
    isChanged: boolean | false,
}

const emptyFileObject:attachmentFileData = {
    enabled: false,
    fieldName: '',
    fileName: '',
    tgId: '',
    mimetype: '',
    isChanged: false,
}

const CustomFieldsList = () => {
    const [botFirstMessage, setBotFirstMessage] = useState('');
    const [botSuccessAuth, setBotSuccessAuth] = useState('');
    const [botErrorAuth, setBotErrorAuth] = useState('');
    const [botErrorMsg, setBotErrorMsg] = useState('');
    const [botErrorMsgReg, setBotErrorMsgReg] = useState('');
    const [htmlErrorAuth, setHtmlErrorAuth] = useState('');
    const [htmlIndexText, setHtmlIndexText] = useState('');
    const [dsMessageText, setDsMessageTest] = useState('');

    const [firstMessageattachmentFile, setFirstMessageAttachmentFile] = useState<any>(null)
    //// Сохраняем инфо о файле в объект
    const [firstMessageFileObject, setFirstMessageFileObject] = useState<attachmentFileData>({
        ...emptyFileObject, fieldName: 'firstMsgAttachment'
    });

    ////
    const [withFirstMessageAttachment, setFirstMessageWithAttachment] = useState(false);
    const [firstMsgAttachmentTgid, setFirstMessageAttachmentTgid] = useState('');
    const [firstMsgAttachmentName, setFirstMessageAttachmentName] = useState('');

    const toast = useRef<any>();

    const showWarn = (msg:any) => {
        toast?.current.show({ severity: 'error', summary: 'Ошибка', detail: msg, life: 3000 });
    };

    const showSuccess = (msg:any) => {
        toast?.current.show({ severity: 'success', summary: 'Сохранено!', detail: msg, life: 3000 });
    };

    const saveData = async () => {
        const attachments:attachmentFileData[] = [];

        // Вложение для приветственного сообщения
        // name == fd.append('name)!!!
        attachments.push(firstMessageFileObject)

        const res = await customFieldsStore.sendData({
            botFirstMessage,
            botSuccessAuth,
            botErrorAuth,
            htmlErrorAuth,
            htmlIndexText,
            botErrorMsg,
            botErrorMsgReg,
            dsMessageText,
            attachments
        }, [firstMessageattachmentFile]);

        if (res) {
            showSuccess('');
        } else {
            showWarn('');
        }
    }

    const getData = async () => {
        const res = (await customFieldsStore.getData()).data;

        if (res && res.length > 0) {
            res.map(
                (item: {id:number, meta_key:string, meta_value:string}) => {
                switch (item.meta_key) {
                    case "botFirstMessage": setBotFirstMessage(item.meta_value); break;
                    case "botSuccessAuth": setBotSuccessAuth(item.meta_value); break;
                    case "botErrorAuth": setBotErrorAuth(item.meta_value); break;
                    case "htmlErrorAuth": setHtmlErrorAuth(item.meta_value); break;
                    case "htmlIndexText": setHtmlIndexText(item.meta_value); break;
                    case "botErrorMsg": setBotErrorMsg(item.meta_value); break;
                    case "botErrorMsgReg": setBotErrorMsgReg(item.meta_value); break;
                    case "dsMessageText": setDsMessageTest(item.meta_value); break;
                    case "firstMsgAttachment":
                        const msgAttData = JSON.parse(item.meta_value);
                        setFirstMessageFileObject({
                            ...firstMessageFileObject,
                            enabled: msgAttData.enabled,
                            mimetype: msgAttData.mimetype,
                            fileName: msgAttData.fileName,
                            tgId: msgAttData.tgId,
                        })
                        // setFirstMessageWithAttachment(msgAttData.enabled);
                        // setFirstMessageAttachmentTgid(msgAttData.tgId);
                        // setFirstMessageAttachmentName(msgAttData.name);
                        //TODO:: Обработка уже присутствующего элемента и что оно не обновлено
                        break;
                }
            })
        }
    }

    const FirstMessageAttachmentSelector = () => {
        return (
            <>
            <div className="field">
                    <label><b>Вложение приветственного текста (png, gif, pdf, аудио)</b></label> <br />
                    <InputSwitch checked={firstMessageFileObject?.enabled} onChange={(e) => {
                        setFirstMessageFileObject({
                            ...firstMessageFileObject, 
                            enabled: e.value
                        })
                        if (!e.value) {
                            setFirstMessageAttachmentFile(null);
                            setFirstMessageFileObject({
                                ...emptyFileObject,
                                isChanged: true,
                                fieldName: 'firstMsgAttachment'
                            });  
                        }
                    }} />
                </div>

                {firstMessageFileObject.enabled && firstMessageFileObject.fileName == '' &&
                    <>
                        <input 
                        type="file" 
                        className="fileUploader__input" 
                        // accept=""
                        onChange={(e) => {
                            const file = e.target.files?e.target.files[0]:false
                            if (file) {
                                setFirstMessageFileObject({
                                    ...firstMessageFileObject,
                                    enabled: true,
                                    fileName: file.name,
                                    mimetype: '',
                                    tgId: '',
                                    isChanged: true,
                                })
                            }

                            setFirstMessageAttachmentFile(file?file:null);
                        }} /><br /><br />
                    </>
                    }

                {firstMessageFileObject.enabled && firstMessageFileObject.fileName && 
                    <div>
                        <button onClick={() => {
                            setFirstMessageAttachmentFile(null)
                            setFirstMessageFileObject({...emptyFileObject, isChanged: true, enabled: false, fieldName: 'firstMsgAttachment'})
                        }}>✖</button> &nbsp;
                        Выбран файл: {firstMessageFileObject.fileName} <br />
                        <br /><br />
                    </div>
                }
            </>
        )
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Button label="Сохранить" className="p-button-success mr-2 mb-2" onClick={saveData} />
                    <h3>Сообщения от бота</h3>
                    <h6>
                        <div>*Жирно*</div>
                        <div>_Курсив_</div>
                        <div>__Подчёркивание__</div>
                        <div>~Зачёркнуто~</div>
                    </h6>
                    <div>
                        <h5>Приветственный текст (после нажатия кнопки "запустить")</h5>
                        <InputTextarea 
                            placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botFirstMessage} onChange={(e) => {setBotFirstMessage(e.target.value)}} />
                            <br />
                            <br />
                            <FirstMessageAttachmentSelector />
                    </div><br />
                    <div>
                        <h5>Успешная авторизация</h5>
                        <h6>Для отправки сообщения с указанием имени пользователя добавьте тег: "[username]"</h6>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botSuccessAuth} onChange={(e) => {setBotSuccessAuth(e.target.value)}} />
                    </div><br />
                    <div>
                        <h5>Номер отсутствует в базе</h5>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botErrorAuth} onChange={(e) => {setBotErrorAuth(e.target.value)}} />
                    </div><br />
                    <div>
                        <h5>Ошибочное сообщение боту (Пользователь не зарегистрирован)</h5>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botErrorMsg} onChange={(e) => {setBotErrorMsg(e.target.value)}} />
                    </div><br />

                    <div>
                        <h5>Ошибочное сообщение боту (Пользователь зарегистрирован)</h5>
                        <h6>Для отправки сообщения с указанием имени пользователя добавьте тег: "[username]"</h6>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botErrorMsgReg} onChange={(e) => {setBotErrorMsgReg(e.target.value)}} />
                    </div><br />



                    <h3>Текст внутри WebApp</h3>
                    <h4>Действуют все стандартные html-теги</h4> <br />
                    <div>
                        <h5>Телефон не авторизован</h5>
                        <InputTextarea 
                            placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={htmlErrorAuth} onChange={(e) => {setHtmlErrorAuth(e.target.value)}} />
                    </div><br />

                    <div>
                        <h5>Текст на первом экране категорий</h5>
                        <InputTextarea 
                            placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={htmlIndexText} onChange={(e) => {setHtmlIndexText(e.target.value)}} />
                    </div><br />

                    <h3>Сообщение от доксферы</h3>
                    <h6>
                        <div>Шорткоды</div>
                        <div>[sender_name] - Имя отправителя</div>
                        <div>[target_title] - Заголовок отправляемой записи</div>
                    </h6>
                    <div>
                        <h5>Шаблон</h5>
                        <InputTextarea 
                            placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={dsMessageText} onChange={(e) => {setDsMessageTest(e.target.value)}} />
                    </div><br />

                    <Button label="Сохранить" className="p-button-success mr-2 mb-2" onClick={saveData} />
                </div>
            </div>

            <Toast ref={toast} />
        </div>
    )
}

export default observer(CustomFieldsList)