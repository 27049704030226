import BotMsgService from "../services/BotMsgService";

export interface buttonDataInterface {
    text?: string,
    url?: string,
}

export interface UploadedFileItemInterface {
    name: string,
    path: string,
    url: string,
    isRemoved: boolean,
    isUploaded: boolean,
}

export interface messageDataInterface {
    message: string,
    buttonData: buttonDataInterface | boolean,
    catalogButton: boolean,
    imagesData: UploadedFileItemInterface[] | boolean,
    attachmentFile: File | boolean
    techName: string,
}

class messageStore {
    async sendMessage(data: string, messageData: messageDataInterface) {
        BotMsgService.sendMessage(data, messageData);
    }

    async uploadImages(files: FileList) {
        return await BotMsgService.uploadImages(files);
    }
}

export default new messageStore();