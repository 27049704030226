import $api from "../http";

export default class MedService {
    static async UploadMeds (file:any) {
        const data = new FormData() ;
        data.append('medfile', file);
        
        return $api.post("/admin/upload/medlist", data, {
            headers: {
                'Content-Type': file.type
            }
        });
    }

    static async getMedList(page: any) {
        return $api.get(`/admin/meds/list/${page}`)
    }
}