import { observer } from "mobx-react-lite"
import React, { FC, useEffect, useState } from "react";
import UsersStore from "../../store/usersStore";
import { IUser } from "../../models/IUser";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { NavLink } from "react-router-dom";

const UserList:FC = () => {
    const usersStore = new UsersStore();
    const [users, setUserList] = useState<IUser[]>([]);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [userToDelete, setUserToDelete] = useState('');
    const [idToDelete, setIdToDelete] = useState<any>(-1);

    const removeDialogFooter = (
        <>
            <Button type="button" label="Нет" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" autoFocus />
            <Button type="button" label="Да" icon="pi pi-check" onClick={() => removeUser()} className="p-button-text"  />
        </>
    );

    const showRemoveDialog = (name:any, id:any) => {
        setDisplayConfirmation(true);
        setUserToDelete(name);
        setIdToDelete(id);
    }

    const removeUser = () => {
        setDisplayConfirmation(false);
        console.log(idToDelete);
        const doRemove = async () => {
            await usersStore.removeUser(idToDelete);
        }

        let user:any = 0;
        for (user in users) {
            // console.log(user);
            if (users[user].id == idToDelete) {
                users.splice(user, 1);
            }
        }

        doRemove();
    }


    useEffect(() => {
        const getUsers = async () => {
            const users = await usersStore.loadUsers()
            setUserList(users);
            // console.log(users);
        }

        getUsers();
    }, [])

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="card__header">
                        <h5>Пользователи</h5>
                        <NavLink to="/users/new"><Button label="Создать нового" className="p-button p-component p-button-outlined mr-2 mb-2" /></NavLink>
                    </div>
                    <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">

                        <table className="p-datatable-table" role="table">
                            <thead className="p-datatable-thead">
                                <tr role="row">
                                    <th className="" role="columnheader"></th>
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Имя</span>
                                        </div>
                                    </th>
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Логин</span>
                                        </div>
                                    </th>
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Дата создания</span>
                                        </div>
                                    </th>

                                    <th className="" role="columnheader"></th>
                                </tr>
                            </thead>
                            <tbody className="p-datatable-tbody">
                                {users.length>0?users.map(user => 
                                    <tr key={user.id} role="row" className="">
                                        <td role="cell" className="text-center cell-button"><NavLink to={'/users/'+user.id}><Button icon="pi pi-pencil" className="p-button-blank" onClick={() => console.log(user.id)} /></NavLink></td>
                                        <td role="cell">{user.name}</td>
                                        <td role="cell">{user.login}</td>
                                        <td role="cell">{user.date_created}</td>
                                        <td role="cell" className="text-center cell-button">
                                            {/* <NavLink to={'/users/'+user.id}><Button icon="pi pi-pencil" className="p-button-blank" onClick={() => console.log(user.id)} /></NavLink> */}
                                            <Button icon="pi pi-trash" className="p-button-blank" onClick={() => showRemoveDialog(user.name, user.id)} />
                                        </td>
                                    </tr>
                                ):false}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <Dialog header="Подтверждение" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={removeDialogFooter}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        Вы действительно хотите удалить пользователя {userToDelete}? 
                    </span>
                </div>
            </Dialog>
        </div>
    )
}

export default observer(UserList);