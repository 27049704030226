import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StatStore from "../../store/statStore";
import Paginator from "../Paginator";

interface BotMessages {
    id: string,
    user: string,
    phone: string,
    message: string,
    timestamp: string,
}

const MessageLog = () => {
    const { page } = useParams();
    const statStore = new StatStore();
    const [data, setData] = useState<BotMessages[]>([]);
    const [lastPage, setLastPage] = useState('');

    const getData = async () => {
        const result = await statStore.getBotMessageLog(page?page:0);
        setData(result.data)
        setLastPage(result.pageCount)
    }

    useEffect(() => {
        getData();
    }, [page])

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="card__header card__header--wbuttons ">
                        <h5>Нежелательные явления</h5>
                        <button className="header__csv-btn" onClick={() => {statStore.getCSV('messages')}}>Выгрузить в csv</button>
                    </div>
                    <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                        <table className="p-datatable-table" role="table">
                            <thead className="p-datatable-thead">
                                <tr role="row">
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Пользователь</span>
                                        </div>
                                    </th>
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Телефон</span>
                                        </div>
                                    </th>
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Сообщение</span>
                                        </div>
                                    </th>
                                    <th className="" role="columnheader">
                                        <div className="p-column-header-content">
                                            <span className="p-column-title">Время получения сообщения</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="p-datatable-tbody">
                                {data.length>0?data.map((item) => (
                                    <tr role="row" key={item.id}>
                                        <td role="cell">{item.user}</td>
                                        <td role="cell">{item.phone}</td>
                                        <td role="cell">{item.message}</td>
                                        <td role="cell">{item.timestamp}</td>
                                    </tr>
                                )):<tr></tr> }
                            </tbody>
                        </table>
                        { lastPage != '0' && 
                                <Paginator curPage={page?page:'0'} lastPage={parseInt(lastPage)-1} url="/log/messages" />}
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default observer(MessageLog);