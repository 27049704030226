import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import PostCategoriesList from "./PostCategoriesList";

const PostHome:FC = () => {

    return (
        <>
            <PostCategoriesList />
        </>
    )
}

export default observer(PostHome);