import $api from "../http";
import FileDownload from "js-file-download"

export default class StatService {
    static async getBotMessages(page: any) {
        return $api.get(`/admin/log/bot/${page}`);
    }

    static async getUserData(page: any) {
        return $api.get(`/admin/stat/users/${page}`);
    }

    static async getFullUserData(page: any) {
        return $api.get(`/admin/stat/fullusers/page/${page}`);
    }

    static async getUserActions(tg_id: number) {
        return $api.get(`/admin/stat/fullusers/${tg_id}`);
    }

    static async getCSV(type: string) {
        await $api.get(`/admin/stat/get/${type}`, {
            responseType: 'blob'
        }).then((res) => {
            let filename = Math.floor(Date.now()/1000).toString();
            switch (type) {
                case 'messages':
                    filename = `tg_messages__${filename}.csv`
                    break;
                case 'type1':
                    filename = `tg_actions_${filename}.csv`
                    break;
                case 'type2':
                    filename = `tg_users_${filename}.csv`
                    break;
            }
            // console.log(res);
            FileDownload(res.data, filename)
        });
        

        return false;
    }
}