import { AxiosResponse } from "axios";
import $api from "../http";
import { IUser } from "../models/IUser";

export default class UserService {
    static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/admin/users');
    }

    static async updateCurrentUser(name: string, password: string = '') {
        return $api.put('/admin/user/edit', {name, password});
    } 

    static async removeCurrentUserSessions() {
        return $api.delete('/admin/user/remove-sessions')
    }

    static async getUserList() {
        return $api.get('/admin/users');
    }

    static async createUser(name:any, login:any, password:any, repeat_password:any, accessType: any, roles: any) {
        return $api.post('/admin/user/add', {name, login, password, repeat_password, accessType, roles})
    }

    static async getUser(id:any) {
        return $api.get('/admin/user/'+id);
    }

    static async editUser(id:any, name:any, login:any, password:any, repeat_password:any, accessType: any, roles: any) {
        return $api.put('/admin/user/'+id+'/edit', {id, name, login, password, repeat_password, accessType, roles})
    }

    static async removeUser(id:any) {
        return $api.delete('/admin/user/'+id+'/remove')
    }
}