import { observer } from "mobx-react-lite";
import { InputText } from "primereact/inputtext";
import React, { FC, useRef, useState, FormEvent, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import PostStore from "../../store/postStore";
import { Toast } from "primereact/toast";
import { InputTextarea } from 'primereact/inputtextarea';
import config from "../../config";
// import EditorContainer from "../EditorComponent";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import PostService from "../../services/PostService";

const PostEdit = (props: any,) => {

    const toast = useRef<any>();
    const postStore = new PostStore();

    const { type, postId, parent, onCreate } = props;
    const [isEdit, setEdit] = useState(postId?true:false)
    const [postTitle, setPostTitle] = useState('');
    const [postType, setPostType] = useState(false);
    const [postLink, setPostLink] = useState('');
    const [postTag, setPostTag] = useState('');
    const [postUTM, setPostUTM] = useState('');
    const [postImage, setPostImage] = useState('');
    const [postParent, setPostParent] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionEditor, setDescriptionEditor] = useState<EditorState>();
    const [postHidden, setPostHidden] = useState(false);
    const [postPriority, setPostPriority] = useState('');
    const [isFileUrl, setIsFileUrl] = useState(false);
    const [postFileUrl, setPostFileUrl] = useState('');
    const [fileCheckState, setFileCheckState] = useState<false|"empty"|"waiting"|"error"|"error_size"|"success"|"responce_error"|"no_headers"|"file_not_found"|"no_size"|"size_error"|"no_file">("empty")
    const [submitBtnStatus, setSubmitBtnStatus] = useState<boolean>(true);

    
    useEffect(() => {
        if (type == 'edit' && postId && postId !== -1 ) {
            getPostData(postId);
        } 
    }, [])
    

    const showWarn = (msg:any, severity:any = 'error', summary:any = 'Ошибка') => {
        toast.current.show({ severity: severity, summary: summary, detail: msg, life: 3000 });
    };

    const showMsg = (text: any) => {
        showWarn(text, 'success', 'Успешно')
    }

    const showErrorMsg = (result:any) => {
        showWarn(result, 'error')
    }


    const getPostData = async (id:string) => {
        const res = await postStore.getCategoryItem(id);
        const {title, isPost, image, parent, description, content, isVisible, tags, priority, utm,  isFileUrl, postFileUrl} = res;
        
        setPostTitle(title);
        setPostType(isPost==1?true:false);
        setPostImage(image);
        setPostParent(parent)
        setDescription(description);
        setDescriptionEditor(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(description).contentBlocks
                )
            )
        );
        setPostLink(content)
        setPostImage(image)
        setPostHidden(!isVisible);
        setPostTag(tags);
        setPostPriority(priority);
        setPostUTM(utm);
        setIsFileUrl(isFileUrl==1?true:false);
        setPostFileUrl(postFileUrl);
    }

    const submitPostForm = (e:FormEvent) => {
        e.preventDefault();
        if (fileCheckState != 'empty' && fileCheckState != 'success') { return; }

        if (postId) {
            saveItem(postId);
        } else {
            createItem();
        }
    }

    const uploadImage = async (image:any) => {
        let result = true;
        await postStore.uploadIcon(image[0]).then((res) => {
            if (!res.path) {
                toast.current.show({ severity: 'error', summary: 'Произошла ошибка', detail: res, life: 3000 });
            } else {
                toast.current.show({ severity: 'info', detail: 'Изображение загружено', life: 3000 });
                setPostImage(res.path);

                console.log(res);
            }
        });

        return result;
    }

    const saveItem = async (id:string) => {

        const res = await postStore.updateCategoryItem(
            id, postTitle, postImage, postType, postLink, 
            postParent, description, postHidden, postTag, 
            postPriority, postUTM, isFileUrl, postFileUrl)

        if (res == id) {
            showMsg('Элемент успешно изменён!');
        } else {
            showErrorMsg(res);
        }
    }

    const createItem = async () => {
        const res = await postStore.createCategoryItem(
            postTitle, postImage, postType, postLink, 
            parent, description, postHidden, postTag, 
            postPriority, postUTM, isFileUrl, postFileUrl);
        
        if (res.message) {
            showErrorMsg(res.message);
        } else {
            showMsg('Элемент создан!');
            onCreate();
        }
    }

    const isValidUrl = (url) => {
        try {
            return Boolean(new URL(url));
        } catch (e) {
            return false;
        }
    }

    const checkFileByUrl = (url) => {
        setPostFileUrl(url);
        if (url.length == 0) { setFileCheckState('empty'); return; }

        setFileCheckState('waiting');

        if (!isValidUrl(url)) { setFileCheckState('error'); return; }

        PostService.checkFileUrl(url)
            .then((result) => {
                console.log(result.data);    
                setFileCheckState(result.data);
            });
    }

    const FileCheckerComponent = () => {

        switch (fileCheckState) {
            case "empty": 
            case "waiting": 
            case "error": 
            case "responce_error":
            case "no_headers":
            case "error_size": 
            case "file_not_found":
            case "no_size":
            case "size_error": 
            case "no_file":
                setSubmitBtnStatus(true);
                break;

            case "success": 
                setSubmitBtnStatus(false);
                break;
        }

        switch (fileCheckState) {
            case "empty": 
                return <></>
            case "waiting": 
                return <div className="fileCheckStr">Проверка файла...</div>
            case "error": 
            case "responce_error":
            case "no_headers":
                return <div className="fileCheckStr">Ошибка проверки</div>
            case "error_size": 
                return <div className="fileCheckStr">Файл слишком большой</div>
            case "file_not_found":
            case "no_file":
                return <div className="fileCheckStr">Файл не найден</div>
            case "no_size":
                return <div className="fileCheckStr">Невозможно получить размер файла</div>
            case "size_error": 
                return <div className="fileCheckStr">Ошибка. Файл больше 25мб</div>
            case "success": 
                return <div className="fileCheckStr">Файл соответствует требованиям</div>
        }
        
        return (<></>)
    }


    return (
        // <div className="grid">

            <div className="">
                <Toast ref={toast} />

                <form onSubmit={(e) => submitPostForm(e)} className="p-fluid">
                    {/* <h5>{isEdit?"Редактирование элемента":"Создание элемента"}</h5> */}
                    <div className="field">
                        <label><b>Отображение отключено</b></label> <br />
                        <InputSwitch checked={postHidden} onChange={(e) => setPostHidden(e.value)} />
                    </div>

                    <div className="field">
                        <label htmlFor="name"><b>Название</b></label>
                        <InputText id="name" type="text" value={postTitle} onChange={(e:any) => setPostTitle(e.target.value)} /> 
                    </div>

                    <div className="field">
                        <label><b>Точка выхода?</b></label> <br />
                        <InputSwitch checked={postType} onChange={(e) => setPostType(e.value)} />
                    </div>

                    { postType === true &&
                        <div className="postData">
                            <div className="field">
                                <label htmlFor="post_link"><b>Теги (через запятую)</b></label>
                                <InputText id="post_link" type="text" value={postTag} onChange={(e:any) => setPostTag(e.target.value)} /> 
                            </div>

                            <div className="field">
                                <label><b>Ссылка на файл</b></label> <br />
                                <InputSwitch checked={isFileUrl} onChange={(e) => setIsFileUrl(e.value)} />
                            </div>

                            { isFileUrl && 
                                <div className="field">
                                    <label htmlFor="name"><b>Ссылка на файл</b></label>
                                    <InputText id="name" type="text" value={postFileUrl} onChange={(e:any) => {
                                        checkFileByUrl(e.target.value);
                                    }} /> 
                                    <FileCheckerComponent />
                                </div>
                            }

                            { !isFileUrl && 
                                <div className="field">
                                    <label htmlFor="post_link"><b>Ссылка</b></label>
                                    <InputText id="post_link" type="text" value={postLink} onChange={(e:any) => setPostLink(e.target.value)} /> 
                                </div>
                            }
                            <div className="field">
                                <label htmlFor="post_link">
                                    <b>UTM-метки <br /> </b>
                                    (Например: "&utm1=значение&utm2=значение", без пробелов и кавычек, начиная с "&" )
                                    </label>
                                <InputText id="post_link" type="text" value={postUTM} onChange={(e:any) => setPostUTM(e.target.value)} /> 
                            </div>
                        </div>
                    }

                    { postType === false && 
                        <div className="categoryData">
                            <div className="editor">
                                <Editor
                                    editorState={descriptionEditor}
                                    onEditorStateChange={(state) => {
                                        setDescriptionEditor(state);
                                    }}
                                    onChange={(e) => {
                                        const data = descriptionEditor?.getCurrentContent();
                                        if (data) {
                                            const html = stateToHTML(data)
                                            setDescription(html);
                                        }
                                    }}
                                    toolbar={{
                                        options: ['inline', 'list', 'history' ],
                                        inline: { inDropdown: true },
                                    }}
                                />
                            </div>
                        
                        </div>
                    }

                    {postParent == '0' && 
                        <div className="field">
                            <label htmlFor="post_link"><b>Приоритет для специальностей (через запятую)</b></label>
                            <InputTextarea id="post_link" value={postPriority} onChange={(e:any) => setPostPriority(e.target.value)} /> 
                        </div>
                        }

                    <div className="field"> 
                        <label><b>Иконка</b></label>
                        <br />
                        <input type="file" name="icon" accept="image/jpeg,image/jpg,image/png,.svg" onChange={(e:any) => {
                            uploadImage(e.target.files);
                        }}/>
                        <br /><br />
                        {postImage!='' && 
                            <img src={`${config.API_URL}${postImage}`} className="post__icon" onClick={() => setPostImage('')} />
                        }
                    </div>

                    
                     
                    <div className="post__save-btn">
                        <Button disabled={isFileUrl && submitBtnStatus} label="Сохранить" className="p-button-raised p-button-success mr-2 mb-2"></Button>
                    </div>
                </form>
            </div>
        // </div>
    )
}

export default observer(PostEdit);